@import '../../scss/components_base';
.holder {
  position: relative;
  width: 100%;
  height: calc(4 * var(--vh));
  // overflow: hidden;
}

.panels {
  position: relative;
  width: 100%;
  height: 100vh; //var(--vh);
  // white-space: nowrap;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.panel {
  position: absolute;
  display: inline-block;
  background: white;
  width: 100%;
  max-width: 100%;
  height: 100%;
  // z-index: 1;
  // display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.introContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.logo {
  position: absolute;
  top: var(--page-gutter);
  left: var(--page-gutter);
  color: white;
}

.textContent {
  margin-top: 43px;
  margin-left: 27px;
  margin-right: 27px;
}
.contentContactIntro {
  flex: 1;
}
.contentContactLabel {
  text-align: center;
  margin-bottom: 50px;
}
