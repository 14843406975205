@import '../../scss/components_base';
.holder {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.container {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  white-space: nowrap;
  overflow: hidden;
}

.imageContainerLeft {
  position: relative;
  z-index: 1;
  display: inline-block;
  white-space: nowrap;
  vertical-align: top;

  width: 20vw;
  height: 100%;
}

.imageFirst {
  position: relative;
  display: inline-block;
  white-space: normal;
  width: 55vw;
  height: 100%;
  background: white;
}
.imageSecond {
  position: relative;
  display: inline-block;
  white-space: normal;
  width: 50vw;
  height: 100%;
}

.whiteRect {
  position: relative;
  z-index: 3;

  display: inline-block;
  vertical-align: top;
  white-space: normal;
  width: 65vw;

  height: 100%;
  background-color: white;
  text-align: center;

  text-transform: uppercase;
}

.imageContainerRight {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 50vw;
  height: 100%;
}

.contentContact {
  // display: inline-block;
  @include intro;
  padding: rem(50);
  white-space: normal;
  width: 100%;
  height: 100%;
  padding-left: rem(0);
  padding-right: rem(0);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contentContactIntro {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
}
.contentContactLabel {
}
.contentBlurb {
  @include body;
  text-transform: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: rem(50);
  text-align: left;
  white-space: normal;
  // max-width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 100%;
  background: white;
}
.contentBlurbIntro {
  flex: 1;
  max-width: rem(750);
  align-self: flex-start;
}
